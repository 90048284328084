<template>
  <a-modal
    v-model="show"
    :centered="true"
    :keyboard="false"
    :footer="null"
    :width="750"
    :maskClosable="false"
    @cancel="handleCancleClick"
    class="a1"
    title="用户上传图片">
    <div v-if="pictureList.length === 0" style="text-align: center">
      没有反馈图片
    </div>
    <div v-if="pictureList.length !== 0" v-for="picture in pictureList" class="img-box">
      <img class="picture_size" :src="`${picture}`">
    </div>
  </a-modal>
</template>

<script>
export default {
  name: 'FeedbackPicture',
  props: {
    feedbackPictureVisiable: {
      require: true,
      default: false
    }
  },
  data () {
    return {
      pictureList: []
    }
  },
  computed: {
    show: {
      get: function () {
        return this.feedbackPictureVisiable
      },
      set: function () {
      }
    }
  },
  methods: {
    setPicture (pictures) {
      if (pictures !== null) {
        this.pictureList = pictures.split(',')
      }
    },
    handleCancleClick () {
      this.pictureList = []
      this.$emit('close')
    }
  }
}
</script>

<style lang="less">
  .a1.ant-modal .ant-modal-content .ant-modal-body {
    overflow-y: auto !important;
    max-height: 400px !important;
  }

</style>
<style >
  .picture_size {
    width: 700px;
  }
  .img-box {
    margin-bottom: 16px;
  }
  .img-box:last-child {
    margin-bottom: 0;
  }
</style>
