<template>
  <a-card :bordered="false" class="card-area">

    <common-table
      ref="table"
      path="feedback"
      :columns="columns">

      <template slot="search">
<!--        <a-button ghost type="primary" @click="feedbackTypeManage">反馈类型管理</a-button>-->
      </template>

      <template slot="operation" slot-scope="{record}">
        <action-message @click="viewReply(record)"></action-message>
        <action-view @click="viewPicture(record)"></action-view>
      </template>

      <template slot="deal" slot-scope="{record}">
        <a-dropdown :trigger="['click']" v-if="record.isDeal===0">
          <a-menu slot="overlay" @click="handleIsDealClick(record)">
            <a-menu-item key="1">已处理</a-menu-item>
          </a-menu>
          <a-button type="danger" size="small">待处理 <a-icon type="down"></a-icon></a-button>
        </a-dropdown>
        <a-button v-else type="primary" ghost disabled size="small">已处理 </a-button>
      </template>

    </common-table>

    <feedback-reply
      ref="feedbackReply"
      :feedbackReplyVisiable="feedbackReply.visiable"
      :feedbackReplyData="feedbackReply.data"
      @close="handleReplyClose"
      @success="handleReplySuccess">
    </feedback-reply>

    <feedback-picture
      ref="feedbackPicture"
      :feedbackPictureVisiable="feedbackPicture.visiable"
      @close="handlePictureClose">
    </feedback-picture>

  </a-card>

</template>

<script>

import FeedbackReply from './FeedbackReply'
import FeedbackPicture from './FeedbackPicture'

export default {
  components: {
    FeedbackReply,
    FeedbackPicture
  },
  data () {
    return {
      feedbackReply: {
        visiable: false,
        data: {}
      },
      feedbackPicture: {
        visiable: false,
      }
    }
  },
  computed: {
    columns () {

      return [
        {
          title: '反馈内容',
          dataIndex: 'content'
        },
        {
          title: '反馈类型',
          dataIndex: 'type',
          filters: this.feedbackTypeFilters,
        },
        {
          title: '联系方式',
          dataIndex: 'contactWay'
        },
        {
          title: '反馈时间',
          dataIndex: 'createdAt',
          sorter: true,
        },

        {
          title: '处理',
          dataIndex: 'isDeal',
          scopedSlots: { customRender: 'deal' },
          filters: [
            { text: '未处理', value: '0' },
            { text: '已处理', value: '1' }
          ],
          filterMultiple: false,
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' }
        },
      ]
    }
  },
  created () {
    this.getFeedBackTypeFilter()
  },
  methods: {
    getFeedBackTypeFilter () {
      this.$get('feedback_type', {
        pageSize: 10000000,
        pageNum: 1,
        keyword: ''
      }).then((r) => {
        let data = r.data.rows
        for (let i = 0; i<data.length; i++) {
          this.feedbackTypeFilters[i] = {
            text: data[i].name,
            value: data[i].name
          }
        }
      })
    },
    feedbackTypeManage(){
      this.$router.push(`/user/feedback/type`)
    },
    handlePictureClose () {
      this.feedbackPicture.visiable = false
    },
    viewPicture (record) {
      this.feedbackPicture.visiable = true
      this.$refs.feedbackPicture.setPicture(record.pictures)
    },
    viewReply (record) {
      this.feedbackReply.data = record
      this.feedbackReply.visiable = true
      this.$refs.feedbackReply.setContent(record)
    },
    handleReplyClose () {
      this.feedbackReply.visiable = false
    },
    handleReplySuccess () {
      this.feedbackReply.visiable = false
      this.$message.success('反馈回复成功')
      this.getList()
    },
    handleIsDealClick (record) {
      let that = this
      this.$confirm({
        title: '确定设置为已处理？',
        content: '当您点击确定按钮后，当前反馈将变为已处理并无法重置',
        centered: true,
        onOk () {
          that.$put('feedback/deal', {
            id: record.id,
            isDeal: 1
          }).then(() => {
            that.$message.success('成功设置为已处理')
            that.getList()
          })
        }
      })
    },

    getList () {
      this.$refs.table.getData()
    }
  }
}
</script>
<style lang="less" scoped>
</style>
