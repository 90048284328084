<template>
  <a-modal
    v-model="show"
    :centered="true"
    :keyboard="false"
    :width="600"
    :maskClosable="false"
    @cancel="handleCancleClick"
    title="反馈回复">
    <template slot="footer">
      <a-popconfirm title="是否确定回复？" @confirm="handleSubmit" okText="确定" cancelText="取消">
        <a-button type="primary" v-if="!this.isReply">提交</a-button>
      </a-popconfirm>
      <span v-if="this.isReply">不可更改</span>
    </template>
    反馈内容：<br/>
    <a-textarea
      :autosize="{ minRows: 2, maxRows: 2 }"
      v-model="content"
      readOnly
    >
    </a-textarea>
    回复内容：<br/>
    <a-textarea v-if="this.isReadOnly"
      :autosize="{ minRows: 3, maxRows: 3 }"
      v-model="reply"
      @blur="handleReplyBlur"
      readOnly
    >
    </a-textarea>
    <a-textarea v-if="!this.isReadOnly"
      :autosize="{ minRows: 3, maxRows: 3 }"
      v-model="reply"
      @blur="handleReplyBlur"
    >
    </a-textarea>
    <span style="color: red" v-if="help !== null">
      {{ help }}
    </span>
  </a-modal>
</template>

<script>
import ATextarea from 'ant-design-vue/es/input/TextArea'
export default {
  name: 'FeedbackReply',
  components: {ATextarea},
  props: {
    feedbackReplyVisiable: {
      require: true,
      default: false
    },
    feedbackReplyData: {
      require: true
    }
  },
  data () {
    return {
      content: '',
      reply: null,
      isReadOnly: false,
      isReply: false,
      id: '',
      help: null
    }
  },
  computed: {
    show: {
      get: function () {
        return this.feedbackReplyVisiable
      },
      set: function () {
      }
    }
  },
  methods: {
    handleReplyBlur () {
      if (this.reply === null || this.reply.length === 0) {
        this.help = '回复内容不得为空！'
      } else {
        this.help = null
      }
    },
    setContent (record) {
      this.content = record.content
      this.id = record.id
      if (record.reply) {
        this.reply = record.reply
        this.isReply = true
        // document.getElementById('replyTextArea').readOnly = true
        this.isReadOnly = true
      }
    },
    handleCancleClick () {
      this.reply = null
      this.isReply = false
      this.help = null
      // document.getElementById('replyTextArea').readOnly = false
      this.isReadOnly = false
      this.$emit('close')
    },
    handleSubmit () {
      if (this.reply === null || this.reply.length === 0) {
        this.help = '回复内容不得为空！'
        return
      }
      this.$put('feedback/reply', {
        id: this.id,

        reply: this.reply
      }).then(() => {
        this.reply = null
        this.isReply = false
        // document.getElementById('replyTextArea').readOnly = false
        this.isReadOnly = false
        this.help = null
        this.$emit('success')
      })
    }
  }
}
</script>
